export const tech = [
  {
    id: 1,
    img: "assets/tech.jpg"
  }
]

export const myIcon = [
  {
    id: 1,
    img: "assets/favicon-16x16.png"
  }
]